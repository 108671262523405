<template>
    <div>
        <div class="checkout-card">
            <div v-if="!isNewAddress && isLoggedIn">
                <h6 class="heading text-primary">
                    <font-awesome-icon icon="fa-solid fa-user" />
                    {{ $t('my_details') }}
                </h6>
                <!-- @click.prevent="onAddressSelect('address1')" -->
                <div v-for="(address, index) in addresses" :key="`u-${index}`" class="address-card"
                    :class="address.id == selectedShippingAddressId ? ' active' : ''"
                    @click="selectAddress(address.id)">
                    <font-awesome-icon v-if="address.id == selectedShippingAddressId" class="icon"
                        icon="fa-solid fa-check" />
                    <p v-if="address.company != null">{{ address.company }}</p>
                    <p>{{ address.firstname }} {{ address.lastname }}</p>
                    <p>{{ address.street.join(" ") }}</p>
                    <p>{{ address.city }}, {{ address.postcode }}</p>
                    <p>{{ address.telephone }}</p>
                </div>
                <!-- <div class="address-card" :class="isAddress2 ? ' active' : ''"
                    @click.prevent="onAddressSelect('address2')">
                    <font-awesome-icon v-if="isAddress2" class="icon" icon="fa-solid fa-check" />
                    <p>Jan Janssen</p>
                    <p>Langestraat 1</p>
                    <p>Amsterdam 1234AB</p>
                    <p>Nederland</p>
                    <p>023432123</p>
                </div> -->
                <button class="btn btn-dark" @click="newAddress">Nieuw adres</button>
            </div>
            <div>
                <form action="#" v-if="!isLoggedIn" @submit.prevent="doLogin">
                    <b-form-group id="email-group" :label="$t('email_address') + '*'" label-for="email">
                        <b-form-input id="email" v-model="form.email" type="text" v-on:blur="validateEmail()"
                            :state="form.emailState" :placeholder="$t('email_address_login_form')"
                            aria-describedby="email-feedback"></b-form-input>
                        <b-form-invalid-feedback id="email-feedback">
                            {{ form.emailError }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <div v-if="emailExists">
                        <b-form-group id="password-group" :label="$t('password') + '*'" label-for="password">
                            <b-form-input id="password" v-model="form.password" :type="form.passwordFieldType"
                                v-on:blur="validatePassword()" :state="form.passwordState"
                                aria-describedby="password-feedback"></b-form-input>
                            <b-form-invalid-feedback id="password-feedback">
                                {{ form.passwordError }}
                            </b-form-invalid-feedback>
                            <small class="form-text mt-1 underline">{{ $t('require_your_password') }}</small>
                            <!-- <span class="show-password">
                                <b-link class="span-txt" @click="toggleShowPass">
                                    {{
                                        form.passwordFieldType === "password"
                                            ? $t("show")
                                            : $t("hide")
                                    }}
                                </b-link>
                            </span> -->
                        </b-form-group>
                        <div class="form-group text-right">
                            <button class="btn btn-dark">Inloggen</button>
                        </div>
                    </div>
                    <small class="form-text text-muted mb-2 mt-1">
                        De bevestiging wordt naar dit e-mailadres verzonden.
                        U kunt een account maken na het afrekenen.</small>
                </form>
                <div v-if="!addresses || isNewAddress"> <!-- v-if="isNewAddress" -->
                    <h6 class="heading text-primary">
                        <font-awesome-icon icon="fa-solid fa-home" />
                        {{ $t('delivery_address') }}
                    </h6>
                    <form action="#">
                        <div class="custom-control custom-checkbox">
                            <input v-model="isCompany" type="checkbox" class="custom-control-input" id="is-company" />
                            <label class="custom-control-label" for="is-company">{{
                $t('register_form_company_question') }}</label>
                        </div>
                        <div v-if="isCompany">
                            <!-- <div class="form-group">
                                <label for="companyName">{{ $t('company_name') }} *</label>
                                <input type="companyName" class="form-control" id="companyName" name="companyName"
                                    :placeholder="$t('company_name')" required />
                            </div>
                            <div class="form-group">
                                <label for="kvk-number">{{ $t('kvk_number') }} *</label>
                                <input type="kvk-number" class="form-control" id="kvk-number" name="kvk-number"
                                    required />
                            </div>
                            <div class="form-group">
                                <label for="btw-number">{{ $t('btw_number') }} *</label>
                                <input type="btw-number" class="form-control" id="btw-number" name="btw-number"
                                    required />
                            </div> -->
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name')"
                                    label-for="company">
                                    <b-form-input id="company" v-model="form.company" type="text"
                                        v-on:blur="validateCompany()" :state="form.companyState"
                                        aria-describedby="company-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="company-feedback">
                                        {{ form.companyError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="coc_number-group"
                                    :label="$t('form_coc_number')" label-for="coc_number">
                                    <b-form-input id="coc_number" v-model="form.coc_number" type="text"
                                        v-on:blur="validateCOCNumber()" :state="form.coc_numberState"
                                        aria-describedby="coc_number-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="coc_number-feedback">
                                        {{ form.coc_numberError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')"
                                    label-for="taxvat">
                                    <b-form-input id="taxvat" v-model="form.taxvat" type="text"
                                        v-on:blur="validateVATNumber()" :state="form.taxvatState"
                                        aria-describedby="taxvat-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="taxvat-feedback">
                                        {{ form.taxvatError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name')"
                                label-for="first_name">
                                <b-form-input id="first_name" v-model="form.firstname" type="text"
                                    v-on:blur="validateFirstname()" @input="validateFirstname()"
                                    :state="form.firstnameState" aria-describedby="firstname-feedback"></b-form-input>
                                <b-form-invalid-feedback id="firstname-feedback">
                                    {{ form.firstnameError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name')"
                                label-for="last_name">
                                <b-form-input id="last_name" v-model="form.lastname" type="text"
                                    v-on:blur="validateLastname()" @input="validateLastname()"
                                    :state="form.lastnameState" aria-describedby="lastname-feedback"></b-form-input>
                                <b-form-invalid-feedback id="lastname-feedback">
                                    {{ form.lastnameError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="country-group" :label="$t('form_country')"
                                label-for="country">
                                <b-form-select id="country" v-model="form.address.country_code" :options="countries"
                                    class="form-control select"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="postcode-group" :label="$t('form_postal_code')"
                                label-for="postcode">
                                <b-form-input id="postcode" v-model="form.address.postcode" type="text"
                                    @blur="validatePostcode" @input="validatePostcode"
                                    :state="form.address.postcodeState" aria-describedby="address.postcode-feedback"
                                    :placeholder="postcodePlaceholder" :length="postcodeLength"></b-form-input>
                                <b-form-invalid-feedback id="address.postcode-feedback">
                                    {{ form.address.postcodeError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="showFullAddress" class="account-inputs" id="street-group"
                                key="street-group" :label="$t('form_street')" label-for="street">
                                <b-form-input id="street" v-model="form.address.streetDisplay" type="text"
                                    v-on:blur="validateStreet()" @input="validateStreet()"
                                    :state="form.address.streetDisplayState"
                                    aria-describedby="address.streetDisplay-feedback"></b-form-input>
                                <b-form-invalid-feedback id="address.streetDisplay-feedback">
                                    {{ form.address.streetDisplayError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <b-row>
                            <b-col sm="6" class="first">
                                <div class="form-group-wrap mb-0">
                                    <b-form-group class="account-inputs" id="house_number-group"
                                        :label="$t('form_house_number')" label-for="house_number">
                                        <b-form-input id="house_number" v-model="form.address.house_number" type="text"
                                            v-on:blur="validateHouseNumber()" :state="form.address.house_numberState"
                                            aria-describedby="address.house_number-feedback"></b-form-input>
                                        <b-form-invalid-feedback id="address.house_number-feedback">
                                            {{ form.address.house_numberError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                            </b-col>
                            <b-col sm="6" class="third">
                                <div class="form-group-wrap mb-0">
                                    <b-form-group class="account-inputs" id="addition-group"
                                        :label="$t('form_addition')" label-for="addition">
                                        <b-form-input id="addition" v-model="form.address.addition"
                                            type="text"></b-form-input>
                                    </b-form-group>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- <p class="text-success"><strong>Julianastraat 123, 1234 AB Amsterdam</strong></p> -->
                        <div class="form-group-wrap">
                            <b-form-group v-if="showFullAddress" class="account-inputs" id="city-group" key="city-group"
                                :label="$t('form_city')" label-for="city">
                                <b-form-input id="city" v-model="form.address.city" type="text"
                                    v-on:blur="validateCity()" :state="form.address.cityState"
                                    aria-describedby="address.city-feedback"></b-form-input>
                                <b-form-invalid-feedback id="address.city-feedback">
                                    {{ form.address.cityError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="foundAddress != '' && showFullAddress == false" class="account-inputs"
                                id="add-group" label=" " label-for="add">
                                <div id="add" class="text-success">
                                    <strong>{{ foundAddress }}</strong>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="foundAddresses.length > 0 && showFullAddress == false"
                                class="account-inputs" id="addm-group" label=" " label-for="addm">
                                <div id="addm">
                                    <b-form-radio style="width: 300px" class="account-radios" v-model="selectedAddress"
                                        v-for="(item, index) in foundAddresses" :key="index" name="some-radios"
                                        :value="index">{{ item.text }}</b-form-radio>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="order-check-box form-group">
                            <b-form-checkbox v-if="showManual" id="manual" v-model="showFullAddress" name="manual">{{
                $t("enter_my_address_manually") }}</b-form-checkbox>
                        </div>
                        <!-- <div class="custom-control custom-checkbox">
                            <input v-model="isHandmating" type="checkbox" class="custom-control-input"
                                id="handmating" />
                            <label class="custom-control-label" for="handmating">{{
                    $t('enter_my_address_manually') }}</label>
                        </div> -->
                        <!-- <div v-if="isHandmating">
                            <div class="form-group">
                                <label for="straatnaam">{{ $t('street_name') }} *</label>
                                <input type="straatnaam" class="form-control" id="straatnaam" name="straatnaam"
                                    required />
                            </div>
                            <div class="form-group">
                                <label for="house_number">{{ $t('house_number') }} *</label>
                                <input type="house_number" class="form-control" id="house_number" name="house_number"
                                    required />
                            </div>
                            <div class="form-group">
                                <label for="addition">{{ $t('addition') }} *</label>
                                <input type="addition" class="form-control" id="addition" name="addition" required />
                            </div>
                            <div class="form-group">
                                <label for="place_name">{{ $t('place_name') }} *</label>
                                <input type="place_name" class="form-control" id="place_name" name="place_name"
                                    required />
                            </div>
                            <div class="form-group">
                                <label for="state/province">{{ $t('state/province') }} *</label>
                                <input type="state/province" class="form-control" id="state/province"
                                    name="state/province" required />
                            </div>
                            <div class="form-group">
                                <label for="postal_code">{{ $t('postal_code') }} *</label>
                                <input type="postal_code" class="form-control" id="postal_code" name="postal_code"
                                    required />
                            </div>
                        </div> -->
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone')"
                                label-for="telephone">
                                <b-form-input id="telephone" v-model="form.address.telephone" type="text"
                                    v-on:blur="validatePhone" @input="validatePhone"
                                    :state="form.address.telephoneState" aria-describedby="address.telephone-feedback"
                                    :placeholder="phonenumberPlaceholder"></b-form-input>
                                <b-form-invalid-feedback id="address.telephone-feedback">
                                    {{ form.address.telephoneError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </form>
                </div>
            </div>
            <form action="#">
                <div class="order-check-box mt-1 p-0">
                    <b-form-checkbox id="checkbox-2" name="checkbox-2" label-for="checkbox2"
                        v-model="form.address.default_billing">{{ $t("form_address_also_invoice_address")
                        }}</b-form-checkbox>
                </div>
            </form>
            <div v-if="form.address.default_billing == false">
                <div v-for="(address, index) in addresses" :key="`u-${index}`" class="address-card"
                    :class="address.id == selectedBillingAddressId ? ' active' : ''"
                    @click="selectInvoiceAddress(address.id)">
                    <font-awesome-icon v-if="address.id == selectedShippingAddressId" class="icon"
                        icon="fa-solid fa-check" />
                    <p v-if="address.company != null">{{ address.company }}</p>
                    <p>{{ address.firstname }} {{ address.lastname }}</p>
                    <p>{{ address.street.join(" ") }}</p>
                    <p>{{ address.city }}, {{ address.postcode }}</p>
                    <p>{{ address.telephone }}</p>
                </div>
                <form action="#">
                    <h6 class="heading text-primary">
                        <font-awesome-icon icon="fa-solid fa-dollar" />
                        {{ $t('invoice_address') }}
                    </h6>
                    <div class="order-check-box form-group">
                        <b-form-checkbox id="new-invoice-address" name="new-invoice-address"
                            label-for="new-invoice-address" v-model="newInvoiceAddress">{{ $t("make_new_address")
                            }}</b-form-checkbox>
                    </div>
                    <div v-if="newInvoiceAddress">
                        <div class="form-group">
                            <b-form-checkbox id="checkbox-99" v-model="isInvoiceCompany" name="checkbox-99">{{
                $t("form_are_you_a_company") }}</b-form-checkbox>
                        </div>
                        <div v-if="isInvoiceCompany" class="short-form">
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name')"
                                    label-for="company">
                                    <b-form-input id="company" v-model="form.invoice.company" type="text"
                                        v-on:blur="validateInvoiceCompany()" :state="form.invoice.companyState"
                                        aria-describedby="company-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="company-feedback">
                                        {{ form.invoice.companyError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="coc_number-group"
                                    :label="$t('form_coc_number')" label-for="coc_number">
                                    <b-form-input id="coc_number" v-model="form.invoice.coc_number" type="text"
                                        v-on:blur="validateInvoiceCOCNumber()" :state="form.invoice.coc_numberState"
                                        aria-describedby="coc_number-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="coc_number-feedback">
                                        {{ form.invoice.coc_numberError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="form-group-wrap">
                                <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')"
                                    label-for="taxvat">
                                    <b-form-input id="taxvat" v-model="form.invoice.taxvat" type="text"
                                        :state="form.invoice.taxvatState"
                                        aria-describedby="taxvat-feedback"></b-form-input>
                                    <b-form-invalid-feedback id="taxvat-feedback">
                                        {{ form.invoice.taxvatError }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name')"
                                label-for="first_name">
                                <b-form-input id="first_name" v-model="form.invoice.firstname" type="text"
                                    v-on:blur="validateInvoiceFirstname()" :state="form.invoice.firstnameState"
                                    aria-describedby="firstname-feedback"></b-form-input>
                                <b-form-invalid-feedback id="firstname-feedback">
                                    {{ form.invoice.firstnameError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name')"
                                label-for="last_name">
                                <b-form-input id="last_name" v-model="form.invoice.lastname" type="text"
                                    v-on:blur="validateInvoiceLastname()" :state="form.invoice.lastnameState"
                                    aria-describedby="lastname-feedback"></b-form-input>
                                <b-form-invalid-feedback id="lastname-feedback">
                                    {{ form.invoice.lastnameError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="country-group" :label="$t('form_country')"
                                label-for="country">
                                <b-form-select id="country" v-model="form.invoice.address.country_code"
                                    :options="countries" class="form-control select"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="postcode-group" :label="$t('form_postal_code')"
                                label-for="postcode">
                                <b-form-input id="postcode" v-model="form.invoice.address.postcode" type="text"
                                    :state="form.invoice.address.postcodeState"
                                    aria-describedby="address.postcode-feedback" :placeholder="postcodePlaceholder"
                                    :length="postcodeLength"></b-form-input>
                                <b-form-invalid-feedback id="address.postcode-feedback">
                                    {{ form.invoice.address.postcodeError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="street-group"
                                key="street-invoice-group" :label="$t('form_street')" label-for="street">
                                <b-form-input id="street" v-model="form.invoice.address.streetDisplay" type="text"
                                    v-on:blur="validateInvoiceStreet()" :state="form.invoice.address.streetDisplayState"
                                    aria-describedby="address.streetDisplay-feedback"></b-form-input>
                                <b-form-invalid-feedback id="address.streetDisplay-feedback">
                                    {{ form.invoice.address.streetDisplayError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <b-row>
                            <b-col sm="6" class="first">
                                <div class="form-group-wrap mb-0">
                                    <b-form-group class="account-inputs" id="house_number-group"
                                        :label="$t('form_house_number')" label-for="house_number">
                                        <b-form-input id="house_number" v-model="form.invoice.address.house_number"
                                            type="text" v-on:blur="validateInvoiceHouseNumber()"
                                            :state="form.invoice.address.house_numberState"
                                            aria-describedby="address.house_number-feedback"></b-form-input>
                                        <b-form-invalid-feedback id="address.house_number-feedback">
                                            {{ form.invoice.address.house_numberError }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                            </b-col>
                            <b-col sm="6" class="third">
                                <div class="form-group-wrap mb-0">
                                    <b-form-group class="account-inputs" id="addition-group"
                                        :label="$t('form_addition')" label-for="addition">
                                        <b-form-input id="addition" v-model="form.invoice.address.addition"
                                            type="text"></b-form-input>
                                    </b-form-group>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="form-group-wrap">
                            <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="city-group"
                                key="city-invoice-group" :label="$t('form_city')" label-for="city">
                                <b-form-input id="city" v-model="form.invoice.address.city" type="text"
                                    v-on:blur="validateInvoiceCity()" :state="form.invoice.address.cityState"
                                    aria-describedby="address.city-feedback"></b-form-input>
                                <b-form-invalid-feedback id="address.city-feedback">
                                    {{ form.invoice.address.cityError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false
                " class="account-inputs" id="add-group" label=" " label-for="add">
                                <div id="add">
                                    {{ foundInvoiceAddress }}
                                </div>
                            </b-form-group>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group v-if="foundInvoiceAddresses.length > 0 &&
                showFullInvoiceAddress == false
                " class="account-inputs" id="addm-group" label=" " label-for="addm">
                                <div id="addm">
                                    <b-form-radio style="width: 300px" class="account-radios"
                                        v-model="selectedInvoiceAddress" v-for="(item, index) in foundInvoiceAddresses"
                                        :key="index" name="some-radios" :value="index">{{ item.text }}</b-form-radio>
                                </div>
                            </b-form-group>
                        </div>
                        <div class="order-check-box form-group">
                            <b-form-checkbox v-if="showManual" id="manual2" v-model="showFullInvoiceAddress"
                                name="manual2">{{ $t("enter_my_address_manually") }}</b-form-checkbox>
                        </div>
                        <div class="form-group-wrap">
                            <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone')"
                                label-for="telephone">
                                <b-form-input id="invoiceTelephone" v-model="form.invoice.address.telephone" type="text"
                                    v-on:blur="validateInvoicePhone()" :state="form.invoice.address.telephoneState"
                                    aria-describedby="address.telephone-feedback"></b-form-input>
                                <b-form-invalid-feedback id="address.telephone-feedback">
                                    {{ form.invoice.address.telephoneError }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </div>
                    </div>
                    <!-- <form action="#">
                    <div class="custom-control custom-checkbox">
                        <input v-model="isFactuuradresCompany" type="checkbox" class="custom-control-input"
                            id="is-factuuradres-company" />
                        <label class="custom-control-label" for="is-factuuradres-company">{{
                    $t('register_form_company_question') }}</label>
                    </div>
                    <div v-if="isFactuuradresCompany">
                        <div class="form-group">
                            <label for="companyName">{{ $t('company_name') }} *</label>
                            <input type="companyName" class="form-control" id="companyName" name="companyName"
                                :placeholder="$t('company_name')" required />
                        </div>
                        <div class="form-group">
                            <label for="kvk-number">{{ $t('kvk_number') }} *</label>
                            <input type="kvk-number" class="form-control" id="kvk-number" name="kvk-number" required />
                        </div>
                        <div class="form-group">
                            <label for="btw-number">{{ $t('btw_number') }} *</label>
                            <input type="btw-number" class="form-control" id="btw-number" name="btw-number" required />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fname">{{ $t('form_first_name') }} *</label>
                        <input type="fname" class="form-control" id="fname" name="fname"
                            :placeholder="$t('form_first_name')" required />
                    </div>
                    <div class="form-group">
                        <label for="achternaam">Achternaam *</label>
                        <input type="achternaam" class="form-control" id="achternaam" name="achternaam"
                            placeholder="Achternaam" required />
                    </div>
                    <div class="form-group">
                        <label for="land">Land *</label>
                        <select type="land" class="form-control" id="land" name="land" required>
                            <option value="nederland">Nederland</option>
                            <option value="germany">Germany</option>
                            <option value="pakistan">Pakistan</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="post_code">PostCode *</label>
                        <input type="post_code" class="form-control" id="post_code" name="post_code"
                            placeholder="Postcode" required />
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="huisnummer">Huisnummer *</label>
                                <input type="huisnummer" class="form-control" id="huisnummer" name="huisnummer"
                                    required />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="Toevoeging">Toevoeging</label>
                                <input type="Toevoeging" class="form-control" id="Toevoeging" name="Toevoeging"
                                    required />
                            </div>
                        </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input v-model="isFactuuradresHandmating" type="checkbox" class="custom-control-input"
                            id="factuuradresHandmating" />
                        <label class="custom-control-label" for="factuuradresHandmating">{{
                    $t('enter_my_address_manually') }}</label>
                    </div>
                    <div v-if="isFactuuradresHandmating">
                        <div class="form-group">
                            <label for="straatnaam">{{ $t('street_name') }} *</label>
                            <input type="straatnaam" class="form-control" id="straatnaam" name="straatnaam" required />
                        </div>
                        <div class="form-group">
                            <label for="house_number">{{ $t('house_number') }} *</label>
                            <input type="house_number" class="form-control" id="house_number" name="house_number"
                                required />
                        </div>
                        <div class="form-group">
                            <label for="addition">{{ $t('addition') }} *</label>
                            <input type="addition" class="form-control" id="addition" name="addition" required />
                        </div>
                        <div class="form-group">
                            <label for="place_name">{{ $t('place_name') }} *</label>
                            <input type="place_name" class="form-control" id="place_name" name="place_name" required />
                        </div>
                        <div class="form-group">
                            <label for="state/province">{{ $t('state/province') }} *</label>
                            <input type="state/province" class="form-control" id="state/province" name="state/province"
                                required />
                        </div>
                        <div class="form-group">
                            <label for="postal_code">{{ $t('postal_code') }} *</label>
                            <input type="postal_code" class="form-control" id="postal_code" name="postal_code"
                                required />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="telefoon">{{ $t('telefoonnumber') }} *</label>
                        <input type="telefoon" class="form-control" id="telefoon" name="telefoon"
                            placeholder="324324324324" required />
                    </div>
                </form> -->
                </form>
            </div>
        </div>
        <div class="mb-2 mb-md-0">
            <small class="text-danger">*{{ $t('required_field') }}</small>
        </div>
    </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import {
    validatePostcode,
    validateVatNumber,
    checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
    mixins: [Cart],
    mounted() {
        if (typeof config.customers.defaultCountry != "undefined") {
            this.form.address.country_code = config.customers.defaultCountry;
            this.form.invoice.address.country_code = config.customers.defaultCountry;
        }
        if (config.postcode_validation.enabled == false) {
            this.manual = true;
            this.showManual = false;
        }
        if (config.vat_validation.enabled == false) {
            this.showVatCheck = false;
        }
        this.selectAddress(this.user.default_shipping);
        this.$root.$on("checkFields", () => {
            this.checkFields();
        });
        // if (this.shippingAddress == null) {
        //   this.selectAddress(this.user.default_shipping);
        // }
    },
    data: function () {
        return {
            // isFactuuradres: false,
            // isFactuuradresCompany: false,
            // isFactuuradresHandmating: false,
            // isHandmating: false,
            // isAddress: true,
            // isAddress2: false,
            toggleState: [
                [true, false, false]
            ],
            isCompany: false,
            isNewAddress: false,
            newInvoiceAddress: false,
            isInvoiceCompany: false,
            showFullInvoiceAddress: false,
            isValidInvoiceVatNumber: false,
            isValidVatNumber: false,
            foundInvoiceAddress: "",
            showManual: true,
            typeTimer: null,
            showFullAddress: false,
            foundAddresses: [],
            foundInvoiceAddresses: [],
            foundAddress: "",
            emailExists: false,
            showVatMessage: "",
            showVatCheck: false,
            addressTimer: null,
            form: {
                email: "",
                emailState: null,
                emailError: "",
                emailTimer: null,
                passwordFieldType: "password",
                password: "",
                passwordState: null,
                passwordError: "",
                passwordTimer: null,
                password_confirm: "",
                password_confirmState: null,
                password_confirmError: "",
                password_confirmTimer: null,
                company: "",
                companyState: null,
                companyError: "",
                companyTimer: null,
                firstname: "",
                firstnameState: null,
                firstnameError: "",
                firstnameTimer: null,
                lastname: "",
                lastnameState: null,
                lastnameError: "",
                lastnameTimer: null,
                coc_number: "",
                coc_numberState: null,
                coc_numberError: "",
                coc_numberTimer: null,
                taxvat: "",
                taxvatState: null,
                taxvatError: "",
                taxvatTimer: null,
                address: {
                    country_code: "",
                    postcode: "",
                    postcodeState: null,
                    postcodeError: "",
                    postcodeTimer: null,

                    street: "",
                    streetDisplay: "",
                    streetDisplayState: null,
                    streetDisplayError: "",
                    streetDisplayTimer: null,

                    house_number: "",
                    house_numberState: null,
                    house_numberError: "",
                    house_numberTimer: null,

                    addition: "",
                    city: "",
                    cityState: null,
                    cityError: "",
                    cityTimer: null,

                    telephone: "",
                    telephoneState: null,
                    telephoneError: "",
                    telephoneTimer: null,
                    default_billing: true,
                    default_shipping: true,
                },
                invoice: {
                    isCompany: true,

                    company: "",
                    companyState: null,
                    companyError: "",
                    companyTimer: null,
                    firstname: "",
                    firstnameState: null,
                    firstnameError: "",
                    firstnameTimer: null,
                    lastname: "",
                    lastnameState: null,
                    lastnameError: "",
                    lastnameTimer: null,
                    coc_number: "",
                    coc_numberState: null,
                    coc_numberError: "",
                    coc_numberTimer: null,
                    taxvat: "",
                    taxvatState: null,
                    taxvatError: "",
                    taxvatTimer: null,
                    address: {
                        country_code: "",
                        postcode: "",
                        postcodeState: null,
                        postcodeError: "",
                        postcodeTimer: null,

                        street: "",
                        streetDisplay: "",
                        streetDisplayState: null,
                        streetDisplayError: "",
                        streetDisplayTimer: null,

                        house_number: "",
                        house_numberState: null,
                        house_numberError: "",
                        house_numberTimer: null,

                        addition: "",
                        city: "",
                        cityState: null,
                        cityError: "",
                        cityTimer: null,

                        telephone: "",
                        telephoneState: null,
                        telephoneError: "",
                        telephoneTimer: null,
                        default_billing: true,
                        default_shipping: true,
                    },
                },
                is_subscribed: false,
                checkbox2: false,
                manual: false,
            },
            emailReg:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            regExNlPhone:
                /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
            regExBePhone:
                /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
            regExDePhone:
                /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
            regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
            postcodePlaceholder: "1111AA",
            phonenumberPlaceholder: "0612345678",
            postcodeLength: 6,
        }
    },
    watch: {
        country() {
            if (this.form.address.postcode.length > 0) {
                this.validatePostcode();
            }
            if (this.form.address.telephone.length > 0) {
                this.validatePhone();
            }

            if (this.isCompany == true) {
                if (this.form.taxvat != "") {
                    this.validateVAT();
                }
            }
            switch (this.form.address.country_code) {
                case "NL":
                    this.postcodePlaceholder = "1111AA";
                    this.phonenumberPlaceholder = "0612345678";
                    this.postcodeLength = 6;
                    break;
                case "BE":
                    this.postcodePlaceholder = "1111";
                    this.phonenumberPlaceholder = "+3212345678";
                    this.postcodeLength = 4;
                    break;
                case "DE":
                    this.postcodePlaceholder = "11111";
                    this.phonenumberPlaceholder = "+491234567890";
                    this.postcodeLength = 5;
                    break;
                default:
                    this.postcodePlaceholder = "1111AA";
                    this.phonenumberPlaceholder = "0612345678";
                    this.postcodeLength = 6;
            }
        },
        address() {
            if (this.addressTimer !== null) {
                clearTimeout(this.addressTimer);
            }
            this.addressTimer = setTimeout(
                function (scope) {
                    scope.validateAddress();
                },
                1000,
                this
            );
        },
        invoiceAddress() {
            if (this.form.invoiceAddressTimer !== null) {
                clearTimeout(this.form.invoiceAddressTimer);
            }
            this.form.invoiceAddressTimer = setTimeout(
                function (scope) {
                    scope.validateInvoiceAddress();
                },
                1000,
                this
            );
        },
        invoiceCountry() {
            if (this.form.invoice.address.postcode.length > 0) {
                this.validateInvoicePostcode();
            }
            if (this.isInvoiceCompany == true) {
                if (this.form.invoice.taxvat != "") {
                    this.validateInvoiceVAT();
                }
            }
        },
        vatNumber() {
            if (config.vat_validation.enabled) {
                if (this.form.taxvatTimer !== null) {
                    clearTimeout(this.form.taxvatTimer);
                }
                this.form.taxvatTimer = setTimeout(
                    function (scope) {
                        scope.validateVAT();
                    },
                    1000,
                    this
                );
            }
        },
        invoiceVatNumber() {
            if (config.vat_validation.enabled) {
                if (this.form.invoice.taxvatTimer !== null) {
                    clearTimeout(this.form.invoice.taxvatTimer);
                }
                this.form.invoice.taxvatTimer = setTimeout(
                    function (scope) {
                        scope.validateInvoiceVAT();
                    },
                    1000,
                    this
                );
            }
        },
        postcodeHouseNumber(newVal) {
            if (this.showFullAddress == false) {
                if (this.typeTimer !== null) {
                    clearTimeout(this.typeTimer);
                }
                if (this.foundAddress != "") {
                    this.foundAddress = "";
                }
                if (this.foundAddresses.length > 0) {
                    this.foundAddresses = [];
                }
                //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
                const [newPostcode, newHouseNumber] = newVal.split("|");
                if (newPostcode != "" && newHouseNumber != "") {
                    this.typeTimer = setTimeout(
                        function (scope) {
                            scope.validatePostcodeHouseNumber();
                        },
                        2000,
                        this
                    );
                }
            }
        },
        invoicePostcodeHouseNumber(newVal) {
            if (this.showFullAddress == false) {
                if (this.typeTimer !== null) {
                    clearTimeout(this.typeTimer);
                }
                if (this.foundInvoiceAddress != "") {
                    this.foundInvoiceAddress = "";
                }
                if (this.foundInvoiceAddresses.length > 0) {
                    this.foundInvoiceAddresses = [];
                }
                //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
                const [newPostcode, newHouseNumber] = newVal.split("|");
                if (newPostcode != "" && newHouseNumber != "") {
                    this.typeTimer = setTimeout(
                        function (scope) {
                            scope.validateInvoicePostcodeHouseNumber();
                        },
                        2000,
                        this
                    );
                }
            }
        },
        selectedAddress(newVal) {
            Logger.debug("selectedAddress", "index", newVal)();
            this.form.address.streetDisplay = this.foundAddresses[newVal].street;
            if (this.foundAddresses[newVal].letter != null) {
                this.form.address.addition = this.foundAddresses[newVal].letter;
            } else {
                this.form.address.addition = "";
            }
            this.form.address.city = this.foundAddresses[newVal].city;
            this.validatePostcode();
        },
        selectedInvoiceAddress(newVal) {
            Logger.debug("selectedAddress", "index", newVal)();
            this.form.invoice.address.streetDisplay =
                this.foundInvoiceAddresses[newVal].street;
            if (this.foundInvoiceAddresses[newVal].letter != null) {
                this.form.invoice.address.addition =
                    this.foundInvoiceAddresses[newVal].letter;
            } else {
                this.form.invoice.address.addition = "";
            }
            this.form.invoice.address.city = this.foundAddresses[newVal].city;
            this.validateInvoicePostcode();
        },
        Email() {
            if (this.form.emailTimer !== null) {
                clearTimeout(this.form.emailTimer);
            }
            this.form.emailTimer = setTimeout(
                function (scope) {
                    scope.validateEmail2();
                },
                1000,
                this
            );
        },
    },
    computed: {
        shippingAddress() {
            return this.$store.getters["cart/getShippingAddress"];
        },
        countries() {
            return this.$store.getters["user/getCountries"];
        },
        country() {
            return this.form.address.country_code;
        },
        selectedShippingAddressId() {
            const sa = this.$store.getters["cart/getShippingAddress"];
            if (sa != null) {
                return sa.id;
            } else if (this.addresses.length == 1) {
                return this.addresses[0].id;
            }
            return 0;
        },
        selectedBillingAddressId() {
            const sa = this.$store.getters["cart/getBillingAddress"];
            if (sa != null) {
                return sa.id;
            }
            return 0;
        },
        address() {
            return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
        },
        invoiceAddress() {
            return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
        },
        invoiceCountry() {
            return this.form.invoice.address.country_code;
        },
        postcodeHouseNumber() {
            return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
        },
        invoicePostcodeHouseNumber() {
            return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}`;
        },
        vatNumber() {
            return this.form.taxvat;
        },
        invoiceVatNumber() {
            return this.form.invoice.taxvat;
        },
        Email() {
            return this.form.email;
        },
        Password() {
            return this.form.password;
        },
        PasswordConfirm() {
            return this.form.password_confirm;
        },
        isLoggedIn() {
            return this.$store.getters["user/getIsLoggedIn"];
        },
        addresses() {
            const user = this.$store.getters["user/getCurrentUser"];
            return user.addresses;
        },
        user() {
            return this.$store.getters["user/getCurrentUser"];
        },
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        async validateEmail() {
            if (this.form.email.length == 0) {
                this.form.emailState = false;
                this.form.emailError = this.$t("email_is_required_field");
                return false;
            }
            if (!this.emailReg.test(this.form.email)) {
                this.form.emailState = false;
                this.form.emailError = this.$t("email_is_invalid_field");
                return false;
            }

            this.form.emailState = true;
            return true;
        },
        async validatePassword() {
            if (this.form.password.length == 0) {
                this.form.passwordState = false;
                this.form.passwordError = this.$t("password_is_required_field");
                return false;
            }

            this.form.passwordState = true;
            return true;
        },
        // toggleShowPass() {
        //     this.form.passwordFieldType =
        //         this.form.passwordFieldType === "password" ? "text" : "password";
        // },
        newAddress: function () {
            this.isNewAddress = true;
        },
        // onAddressSelect: function (type) {
        //     if (type == 'address1') {
        //         this.isAddress = true;
        //         this.isAddress2 = false;
        //     } else {
        //         this.isAddress = false;
        //         this.isAddress2 = true;
        //     }
        // },

        // 
        async validateEmail2(data) {
            if (data || data === "") {
                this.form.email = data;
            }
            if (this.form.email.length == 0) {
                this.form.emailState = false;
                this.form.emailError = this.$t("email_is_required_field");
                return false;
            }
            if (!this.emailReg.test(this.form.email)) {
                this.form.emailState = false;
                this.form.emailError = this.$t("email_is_invalid_field");
                return false;
            }
            if (!(await checkEmailExist(this.form.email))) {
                this.emailExists = true;
            } else {
                this.emailExists = false;
            }

            this.form.emailState = true;
            Logger.debug("calling cart/addCartEmail", "store", this.form.email)();
            this.$store.dispatch("cart/addCartEmail", {
                email: this.form.email,
            });
            return true;
        },
        async doLogin() {
            const loginOk = await this.$store.dispatch("user/login", {
                username: this.form.email,
                password: this.form.password,
            });

            if (loginOk == true) {
                const msg = {
                    type: "success",
                    title: this.$t("login_success"),
                    text: this.$t("you_are_logged_in"),
                };
                this.$store.dispatch("messages/sendMessage", { message: msg });
                //this.$router.push({ name: "account" });
            }
        },
        async validateVAT() {
            if (this.isCompany == true) {
                if (
                    this.form.taxvat.substring(0, 2).toUpperCase() ===
                    this.form.address.country_code
                ) {
                    const retval = await validateVatNumber(this.form.taxvat);
                    if (retval.is_valid == true) {
                        this.isValidVatNumber = true;
                        this.form.taxvatState = true;
                    } else {
                        this.isValidVatNumber = false;
                        this.form.taxvatState = false;
                        this.form.taxvatError = this.$t("taxvat_invalid");

                        this.showVatMessage = this.$t("taxvat_invalid");
                    }
                } else {
                    this.isValidVatNumber = false;
                    this.form.taxvatState = false;
                    this.form.taxvatError = this.$t("taxvat_wrong_country");
                }
            } else {
                this.form.taxvatState = null;
            }
        },
        async validateAddress() {
            let isOk = true;
            if (this.isCompany == true) {
                if (this.form.company == "") {
                    isOk = false;
                }
                if (this.form.taxvat == "") {
                    isOk = false;
                }
            }
            if (this.form.firstname == "") {
                isOk = false;
            }
            if (this.form.lastname == "") {
                isOk = false;
            }
            if (this.form.address.country_code == "") {
                isOk = false;
            }
            if (this.form.address.postcode == "") {
                isOk = false;
            }
            if (this.form.address.streetDisplay == "") {
                isOk = false;
            }
            if (this.form.address.house_number == "") {
                isOk = false;
            }
            if (this.form.address.city == "") {
                isOk = false;
            }
            if (this.form.address.telephone == "") {
                isOk = false;
            }

            if (isOk == true) {
                const address = {
                    firstname: this.form.firstname,
                    lastname: this.form.lastname,
                    postcode: this.form.address.postcode,
                    city: this.form.address.city,
                    telephone: this.form.address.telephone,
                    street: `${this.form.address.streetDisplay} ${this.form.address.house_number}${this.form.address.addition}`,
                    country_code: this.form.address.country_code,
                };
                if (this.isCompany == true) {
                    address.company = this.form.company;
                    address.vat_id = this.form.taxvat;
                }
                await this.$store.dispatch("cart/addShippingAddress", {
                    address: address,
                });
                if (this.form.address.default_billing == true) {
                    await this.$store.dispatch("cart/addBillingAddress", {
                        address: address,
                    });
                }
            }
        },
        async validateInvoiceAddress() {
            let isOk = true;
            if (this.isInvoiceCompany == true) {
                if (this.form.invoice.company == "") {
                    isOk = false;
                }
                if (this.form.invoice.taxvat == "") {
                    isOk = false;
                }
            }
            if (this.form.invoice.firstname == "") {
                isOk = false;
            }
            if (this.form.invoice.lastname == "") {
                isOk = false;
            }
            if (this.form.invoice.address.country_code == "") {
                isOk = false;
            }
            if (this.form.invoice.address.postcode == "") {
                isOk = false;
            }
            if (this.form.invoice.address.streetDisplay == "") {
                isOk = false;
            }
            if (this.form.invoice.address.house_number == "") {
                isOk = false;
            }
            if (this.form.invoice.address.city == "") {
                isOk = false;
            }
            if (this.form.invoice.address.telephone == "") {
                isOk = false;
            }

            if (isOk == true) {
                const address = {
                    company: this.form.invoice.company,
                    firstname: this.form.invoice.firstname,
                    lastname: this.form.invoice.lastname,
                    postcode: this.form.invoice.address.postcode,
                    city: this.form.invoice.address.city,
                    telephone: this.form.invoice.address.telephone,
                    street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number}${this.form.invoice.address.addition}`,
                    country_code: this.form.invoice.address.country_code,
                };
                if (this.isCompany == true) {
                    address.company = this.form.company;
                    address.vat_id = this.form.taxvat;
                }

                this.$store.dispatch("cart/addBillingAddress", { address: address });
            }
        },
        validateCompany() {
            if (this.isCompany == true) {
                if (this.form.company == "") {
                    this.form.companyState = false;
                    this.form.companyError = this.$t("company_name_required");
                } else {
                    this.form.companyState = true;
                }
            } else {
                this.form.companyState = null;
            }
        },
        validateFirstname() {
            if (this.form.firstname == "") {
                this.form.firstnameState = false;
                this.form.firstnameError = this.$t("firstname_required");
            } else {
                this.form.firstnameState = true;
            }
        },
        validateLastname() {
            if (this.form.lastname == "") {
                this.form.lastnameState = false;
                this.form.lastnameError = this.$t("lastname_required");
            } else {
                this.form.lastnameState = true;
            }
        },
        validateCOCNumber() {
            if (this.isCompany == true) {
                if (this.form.coc_number == "") {
                    this.form.coc_numberState = false;
                    this.form.coc_numberError = this.$t("coc_number_required");
                } else {
                    this.form.coc_numberState = true;
                }
            } else {
                this.form.coc_numberState = null;
            }
        },
        validateVATNumber() {
            if (this.isCompany == true) {
                if (this.form.taxvat == "") {
                    this.form.taxvatState = false;
                    this.form.taxvatError = this.$t("vat_number_required");
                } else {
                    this.form.taxvatState = true;
                }
            } else {
                this.form.taxvatState = null;
            }
        },
        validateStreet() {
            if (this.form.address.streetDisplay == "") {
                this.form.address.streetDisplayState = false;
                this.form.address.streetDisplayError = this.$t("street_required");
            } else {
                this.form.address.streetDisplayState = true;
            }
        },
        validateHouseNumber() {
            if (this.form.address.house_number == "") {
                this.form.address.house_numberState = false;
                this.form.address.house_numberError = this.$t("house_number_required");
            } else {
                this.form.address.house_numberState = true;
            }
        },
        validateCity() {
            if (this.form.address.city == "") {
                this.form.address.cityState = false;
                this.form.address.cityError = this.$t("city_required");
            } else {
                this.form.address.cityState = true;
            }
        },
        validatePhone() {
            if (this.form.address.country_code == "NL") {
                if (this.form.address.telephone.match(this.regExNlPhone) == null) {
                    this.form.address.telephoneState = false;
                    this.form.address.telephoneError = this.$t("phone_nl_regex_test");
                } else {
                    this.generalPhoneValidation();
                }
            } else if (this.form.address.country_code == "BE") {
                if (this.form.address.telephone.match(this.regExBePhone) == null) {
                    this.form.address.telephoneState = false;
                    this.form.address.telephoneError = this.$t("phone_be_regex_test");
                } else {
                    this.generalPhoneValidation();
                }
            } else if (this.form.address.country_code == "DE") {
                if (this.form.address.telephone.match(this.regExDePhone) == null) {
                    this.form.address.telephoneState = false;
                    this.form.address.telephoneError = this.$t("phone_de_regex_test");
                } else {
                    this.generalPhoneValidation();
                }
            }
        },
        generalPhoneValidation() {
            if (
                !this.form.address.telephone.match(this.regExGenPhone) === null ||
                this.form.address.telephone.length == 0
            ) {
                this.form.address.telephoneState = false;
                this.form.address.telephoneError = this.$t("phone_required");
            } else {
                this.form.address.telephoneState = true;
            }
        },
        async validatePostcodeHouseNumber() {
            if (this.showFullAddress == false) {
                const list = await validatePostcode(
                    this.form.address.postcode,
                    this.form.address.house_number
                );
                if (list.length == 1) {
                    let address = list[0].street + " " + list[0].number;
                    this.form.address.streetDisplay = list[0].street;
                    if (list[0].letter != null) {
                        this.form.address.addition = list[0].letter;
                        address = address + list[0].letter;
                    } else {
                        this.form.address.addition = "";
                    }

                    address = address + ", " + list[0].postcode + " " + list[0].city;

                    this.form.address.city = list[0].city;
                    this.form.address.cityState = true;
                    this.foundAddress = address;
                    this.validatePostcode();
                } else if (list.length == 0) {
                    this.foundAddress = this.$t("postcode_not_found");
                } else {
                    this.foundAddresses = [];
                    list.forEach(function (item) {
                        if (this.form.address.addition == "") {
                            let address = item.street + " " + item.number;
                            if (item.letter != null) {
                                address = address + item.letter;
                            }
                            address = address + ", " + item.postcode + " " + item.city;
                            item.text = address;
                            this.foundAddresses.push(item);
                        } else {
                            if (
                                item.letter != null &&
                                item.letter.toUpperCase() ==
                                this.form.address.addition.toUpperCase()
                            ) {
                                let address = item.street + " " + item.number;
                                address = address + item.letter;
                                address = address + ", " + item.postcode + " " + item.city;
                                item.text = address;
                                this.form.address.streetDisplay = item.street;
                                this.form.address.city = item.city;
                                this.form.address.cityState = true;
                                this.form.address.street = item.street;
                                this.foundAddress = address;
                                this.validatePostcode();
                                return;
                            }
                        }
                    }, this);
                    if (
                        this.foundAddresses.length == 0 &&
                        this.foundAddress.length == 0
                    ) {
                        this.form.address.streetDisplay = "";
                        this.form.address.streetDisplayState = null;
                        this.form.address.city = "";
                        this.form.address.cityState = null;
                        this.foundAddress = this.$t("postcode_not_found");
                    }
                }
            } else {
                if (this.form.address.postcode == "") {
                    this.form.address.postcodeState = false;
                    this.form.address.postcodeError = this.$t("postcode_required");
                } else {
                    this.form.address.postcodeState = true;
                }
            }
        },
        validatePostcode() {
            const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
            const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
            const be = /^[1-9][0-9]{3}$/g;
            if (
                this.form.address.country_code == "NL" &&
                nl.test(this.form.address.postcode)
            ) {
                this.form.address.postcodeState = true;
            } else if (
                this.form.address.country_code == "BE" &&
                be.test(this.form.address.postcode)
            ) {
                this.form.address.postcodeState = true;
            } else if (
                this.form.address.country_code == "DE" &&
                de.test(this.form.address.postcode)
            ) {
                this.form.address.postcodeState = true;
            } else {
                this.form.address.postcodeState = false;
                this.form.address.postcodeError = this.$t("postcode_required");
            }
        },
        async validateInvoiceVAT() {
            if (this.isInvoiceCompany == true) {
                if (
                    this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
                    this.form.invoice.address.country_code
                ) {
                    const retval = await validateVatNumber(this.form.invoice.taxvat);
                    if (retval.is_valid == true) {
                        this.isValidInvoiceVatNumber = true;
                        this.form.invoice.taxvatState = true;
                    } else {
                        this.isValidInvoiceVatNumber = false;
                        this.form.invoice.taxvatState = false;
                        this.form.invoice.taxvatError = this.$t("taxvat_invalid");
                    }
                } else {
                    this.isValidInvoiceVatNumber = false;
                    this.form.invoice.taxvatState = false;
                    this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
                }
            } else {
                this.form.invoice.taxvatState = null;
            }
        },
        validateInvoiceCompany() {
            if (this.isInvoiceCompany == true) {
                if (this.form.invoice.company == "") {
                    this.form.invoice.companyState = false;
                    this.form.invoice.companyError = this.$t("company_name_required");
                } else {
                    this.form.invoice.companyState = true;
                }
            } else {
                this.form.invoice.companyState = null;
            }
        },
        validateInvoiceFirstname() {
            if (this.form.invoice.firstname == "") {
                this.form.invoice.firstnameState = false;
                this.form.invoice.firstnameError = this.$t("firstname_required");
            } else {
                this.form.invoice.firstnameState = true;
            }
        },
        validateInvoiceLastname() {
            if (this.form.invoice.lastname == "") {
                this.form.invoice.lastnameState = false;
                this.form.invoice.lastnameError = this.$t("lastname_required");
            } else {
                this.form.invoice.lastnameState = true;
            }
        },
        validateInvoiceCOCNumber() {
            if (this.isInvoiceCompany == true) {
                // if (this.form.invoice.coc_number == "") {
                //   this.form.invoice.coc_numberState = false;
                //   this.form.invoice.coc_numberError = this.$t("coc_number_required");
                // } else {
                //   this.form.invoice.coc_numberState = true;
                // }
            } else {
                this.form.invoice.coc_numberState = null;
            }
        },
        validateInvoiceStreet() {
            if (this.form.invoice.address.streetDisplay == "") {
                this.form.invoice.address.streetDisplayState = false;
                this.form.invoice.address.streetDisplayError =
                    this.$t("street_required");
            } else {
                this.form.invoice.address.streetDisplayState = true;
            }
        },
        validateInvoiceHouseNumber() {
            if (this.form.invoice.address.house_number == "") {
                this.form.invoice.address.house_numberState = false;
                this.form.invoice.address.house_numberError = this.$t(
                    "house_number_required"
                );
            } else {
                this.form.invoice.address.house_numberState = true;
            }
        },
        validateInvoiceCity() {
            if (this.form.invoice.address.city == "") {
                this.form.invoice.address.cityState = false;
                this.form.invoice.address.cityError = this.$t("city_required");
            } else {
                this.form.invoice.address.cityState = true;
            }
        },
        validateInvoicePhone() {
            if (
                this.form.invoice.address.telephone.substring(0, 3) == "+31" ||
                (this.form.invoice.address.country_code == "NL" &&
                    this.form.invoice.address.telephone.substring(0, 1) !== "+")
            ) {
                if (
                    this.form.invoice.address.telephone.match(this.regExNlPhone) == null
                ) {
                    this.form.invoice.address.telephoneState = false;
                    this.form.invoice.address.telephoneError = this.$t(
                        "phone_nl_regex_test"
                    );
                } else {
                    this.form.invoice.address.telephoneState = true;
                }
            } else if (
                this.form.invoice.address.telephone.substring(0, 3) == "+32" ||
                (this.form.invoice.address.country_code == "BE" &&
                    this.form.invoice.address.telephone.substring(0, 1) !== "+")
            ) {
                if (
                    !this.form.invoice.address.telephone.match(this.regExBePhone) == null
                ) {
                    this.form.invoice.address.telephoneState = false;
                    this.form.invoice.address.telephoneError = this.$t(
                        "phone_be_regex_test"
                    );
                } else {
                    this.form.invoice.address.telephoneState = true;
                }
            } else if (
                this.form.invoice.address.telephone.substring(0, 3) == "+49" ||
                (this.form.invoice.address.country_code == "DE" &&
                    this.form.invoice.address.telephone.substring(0, 1) !== "+")
            ) {
                if (
                    !this.form.invoice.address.telephone.match(this.regExDePhone) == null
                ) {
                    this.form.invoice.address.telephoneState = false;
                    this.form.invoice.address.telephoneError = this.$t(
                        "phone_de_regex_test"
                    );
                } else {
                    this.form.invoice.address.telephoneState = true;
                }
            } else if (
                this.form.invoice.address.telephone.match(this.regExGenPhone) == null
            ) {
                this.form.invoice.address.telephoneState = false;
                this.form.invoice.address.telephoneError = this.$t("phone_required");
            } else {
                this.form.invoice.address.telephoneState = true;
            }
        },
        async validateInvoicePostcodeHouseNumber() {
            if (this.showFullAddress == false) {
                const list = await validatePostcode(
                    this.form.invoice.address.postcode,
                    this.form.invoice.address.house_number
                );
                if (list.length == 1) {
                    let address = list[0].street + " " + list[0].number;
                    this.form.invoice.address.streetDisplay = list[0].street;
                    if (list[0].letter != null) {
                        this.form.invoice.address.addition = list[0].letter;
                        address = address + list[0].letter;
                    } else {
                        this.form.invoice.address.addition = "";
                    }

                    address = address + ", " + list[0].postcode + " " + list[0].city;

                    this.form.invoice.address.city = list[0].city;
                    this.form.invoice.address.cityState = true;
                    this.foundInvoiceAddress = address;
                    this.validatePostcode();
                } else if (list.length == 0) {
                    this.foundInvoiceAddress = this.$t("postcode_not_found");
                } else {
                    list.forEach(function (item) {
                        if (this.form.invoice.address.addition == "") {
                            let address = item.street + " " + item.number;
                            if (item.letter != null) {
                                address = address + item.letter;
                            }
                            address = address + ", " + item.postcode + " " + item.city;
                            item.text = address;
                            this.foundInvoiceAddresses.push(item);
                        } else {
                            if (
                                item.letter != null &&
                                item.letter.toUpperCase() ==
                                this.form.address.addition.toUpperCase()
                            ) {
                                let address = item.street + " " + item.number;
                                address = address + item.letter;
                                address = address + ", " + item.postcode + " " + item.city;
                                item.text = address;
                                this.form.invoice.address.streetDisplay = item.street;
                                this.form.invoice.address.city = item.city;
                                this.form.invoice.address.cityState = true;
                                this.form.invoice.address.street = item.street;
                                this.foundInvoiceAddress = address;
                                this.validateInvoicePostcode();
                                return;
                            }
                        }
                    }, this);
                    if (
                        this.foundInvoiceAddresses.length == 0 &&
                        this.foundInvoiceAddress.length == 0
                    ) {
                        this.form.invoice.address.streetDisplay = "";
                        this.form.invoice.address.streetDisplayState = null;
                        this.form.invoice.address.city = "";
                        this.form.invoice.address.cityState = null;
                        this.foundInvoiceAddress = this.$t("postcode_not_found");
                    }
                }
            } else {
                if (this.form.invoice.address.postcode == "") {
                    this.form.invoice.address.postcodeState = false;
                    this.form.invoice.address.postcodeError =
                        this.$t("postcode_required");
                } else {
                    this.form.invoice.address.postcodeState = true;
                }
            }
        },
        validateInvoicePostcode() {
            if (this.form.invoice.address.postcode == "") {
                this.form.invoice.address.postcodeState = false;
                this.form.invoice.address.postcodeError = this.$t("postcode_required");
            } else {
                this.form.invoice.address.postcodeState = true;
            }
        },
        async selectAddress(id) {
            if (id) {
                await this.$store.dispatch("cart/addShippingAddressById", { id: id });
                if (this.form.address.default_billing == true) {
                    await this.$store.dispatch("cart/addBillingAddressById", { id: id });
                }
            }
        },
        selectInvoiceAddress(id) {
            this.$store.dispatch("cart/addBillingAddressById", { id: id });
        },
        checkFields() {
            this.$store.commit("cart/setStoreValidation", {
                address: [],
            });
        },
    }
}
</script>